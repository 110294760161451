<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('工单记录')}}</span>
        <div>
          <el-button type="primary" v-if="user_role['work_order/appealCreate']" @click='addAdmin = true' size="small">{{$t('创建申诉单')}}</el-button>
          <el-button type="primary" size="small" @click="getUserList(Page.page,1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <!-- 搜索栏 -->
      <div class="search">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input :placeholder="$t('请输入关键字')" clearable v-model="search.name" class="input-with-select"></el-input>
          </el-col>
          <el-col :span="12">
            <el-date-picker v-model="sreachDate" type="datetimerange" align="right" range-separator="-"
              :start-placeholder="$t('开始日期')" :end-placeholder="$t('结束日期')" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-col>
          <el-col :span="6">
            <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
          </el-col>
        </el-row>
      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('ID')" prop="id" fixed="left" width="100" align="center" header-align="left">
        </el-table-column>
        <el-table-column :label="$t('工单号')" prop="wordernum" width="200"></el-table-column>
        <el-table-column :label="$t('申述用户')" prop="appeal_username" width="150"></el-table-column>
        <el-table-column :label="$t('申诉类型')" width="200">
          <template slot-scope="scope">
            <span>{{typeList[scope.row.type]}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('申诉时间')" prop="appeal_time" width="200"></el-table-column>
        <el-table-column :label="$t('状态')" width="100">
          <template slot-scope="scope">
            <span>{{stateList[scope.row.status]}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('备注信息')" prop="remarks" width="200"></el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="getDetail(scope.row)">{{$t('详情')}}</el-button>
            <el-button type="text" size="small"
            v-if="user_role['work_order/appealAgree']&&scope.row.status=='0'"
            @click="Agree(scope.row)">{{$t('同意')}}</el-button>
            <el-button type="text" size="small" v-if="user_role['work_order/appealReject']&&scope.row.status=='0'"
              @click="refuse(scope.row.id)">{{$t('驳回')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('申述工单')" :visible.sync="addAdmin" width="50%">
      <el-row :gutter="20">
        <el-form :model="addform" :label-position="getLable" :rules="addformRules" label-width="100px" ref="addform">
          <el-col :md="12" :sm="24">
            <el-form-item :label="$t('工单id')" prop="id">
              <el-input
                :placeholder="$t('请输入')"
                v-model="addform.id"
                clearable>
              </el-input>
            </el-form-item>
          </el-col><!-- /工单ID -->
          <el-col :md="12" :sm="24">
            <el-form-item :label="$t('申诉类型')" prop="type">
              <el-select v-model="addform.type" clearable :placeholder="$t('请选择')">
                  <el-option
                    v-for="(item,index) in typeList"
                    :key="index"
                    :label="item"
                    :value="index">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col><!-- /申诉类型 -->
          <el-col :span="24">
            <el-form-item :label="$t('备注信息')" prop="remarks">
              <el-input
                :placeholder="$t('请输入')"
                type="textarea"
                v-model="addform.remarks"
                rows="5"
                maxlength="255"
                show-word-limit>
              </el-input>
            </el-form-item>
          </el-col><!-- /备注信息 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDia">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="addMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('工单详情')" :visible.sync="Detail" width="50%">
      <el-row :gutter="20">
          <el-col :sm="12" v-for="item in DetList" class="DetList_item">
            <el-col :span="9" class="label">{{item.label}}</el-col>
            <el-col :span="15" class="context">{{item.value}}</el-col>
          </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Detail = false">{{$t('取消')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../utils/mixin.js'
export default {
  name: 'appeal',
  mixins: [mixin],
  data () {
    return {
      // 添加功能的开关
      addAdmin: false,
      UntreatedData: [],
      // 搜索栏对象
      search: {
        username: ''
      },
      // 时间搜索
      sreachDate: [],
      // 添加功能的表单
      addform: {
        id: '',
        type: '',
        remarks: ''
      },
      // 分页
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      // 详情开关
      Detail: false,
      // 用户权限
      user_role: {},
      // 申报类型列表
      typeList: [
        this.$t('任务处理时间评分'),
        this.$t('客户等待时间评分'),
        this.$t('客户评价评分'),
        this.$t('工单完整性评分'),
        this.$t('按时打卡完成度评分')],
      // 申报状态列表
      stateList: [this.$t('待处理'),this.$t('已处理'),this.$t('驳回')],
      // 添加表单的规则
      addformRules: {
        id: [
          { required: true, message: this.$t('不能为空')}
        ],
        type: [
          { required: true, message: this.$t('不能为空')}
        ],
        remarks: [
          { required: true, message: this.$t('不能为空')}
        ]
      },
      // 详情的数据对象
      DetList: []
    }
  },
  created () {
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
    this.getUserList()
    // console.log(this.$route.params)
  },
  mounted () {},
  beforeUpdate () {},
  computed: {},
  methods: {
    // 获取客户区域列表
    getUserList (page, report) {
      var params = {}; var key
      // 如果点搜索按钮则自动跳回第一页
      if (page) this.Page.page = page
      // 如果时间为空则自动定位最近一个月
      if (this.sreachDate.length > 0 && this.sreachDate != null) {
        params.start_time = this.sreachDate[0]
        params.end_time = this.sreachDate[1]
      } else {
        var time = new Date()
        time.setTime(time.getTime() - 3600 * 1000 * 24 * 31)
        params.start_time = this.$common.formatTime(time)
        params.end_time = this.$common.formatTime(new Date())
        this.sreachDate = [params.start_time, params.end_time]
      }
      //
      for (key in this.search) {
        if (this.search[key] != null && this.search[key] != '') params[key] = this.search[key]
      }
      params.page = this.Page.page
      // 到处报表
      if (report === 1) {
        params.is_report = report
        this.$common.postExcelFile(params, 'work_order/appealLists')
        return
      }
      // 接口函数
      this.$axios.post('work_order/appealLists', params).then((res) => {
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        } else this.$message.warning(Data.message)
      }).catch((error) => console.log(error))
    },
    // 添加
    addMin () {
      const params = {}; let key
      for (key in this.addform) {
        if (this.addform[key] !== '' && this.addform[key] !== null) params[key] = this.addform[key]
        else return alert(`${key}${this.$t('不得为空')}`)
      }
      this.$axios.post('work_order/appealCreate', params).then((res) => {
        if (res.data.code === 0) {
          this.closeDia()
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.getUserList()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 获取详情
    getDetail (scope) {
      // console.log(scope) typeList
      this.DetList = []
      const label = {
        wordernum: `${this.$t('工单号')}:`,
        appeal_username: `${this.$t('申诉人')}:`,
        remarks: `${this.$t('备注信息')}:`,
        handle_username: `${this.$t('审核人')}:`,
        handle_time: `${this.$t('审核时间')}:`,
        handle_score: `${this.$t('审核分数')}:`,
        type: `${this.$t('申诉类型')}:`,
        original_score: `${this.$t('原分数')}:`
      }
      this.Detail = true
      let key
      for (key in scope) {
        if (key in label) {
          this.DetList.push({
            label: label[key],
            value: key === 'type' ? this.typeList[scope[key]] : scope[key]
          })
        }
      }
    },
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    },
    // 关闭弹窗
    closeDia () {
      this.addAdmin = false
      this.$refs.addform.resetFields()
    },
    // 驳回申报
    refuse (id) {
      let that = this
      this.$MessageBox.confirm(that.$t('是否驳回该申述？'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('work_order/appealReject', {
          id
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('驳回成功!')
            })
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消操作')
        })
      })
    },
    // 同意申述
    Agree (scope) {
      let that = this
      this.$MessageBox.prompt(that.$t('请输入分数'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        inputPattern: /[0-10]?/,
        inputErrorMessage: that.$t('格式不正确'),
        inputPlaceholder: `${that.$t('新值必须在')}${scope.original_score}~5之间`
      }).then(({
        value
      }) => {
        var handle_score = Number(value); var params = { id: scope.id }
        if (handle_score != NaN) params.handle_score = handle_score
        this.$axios.post('work_order/appealAgree', params).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('成功!')
            })
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消操作')
        })
      })
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select,
  ::v-deep .el-date-editor {
    width: 100% !important;
  }

  .el-input.is-disabled::v-deep .el-input__inner,
  .el-select::v-deep .el-input.is-disabled .el-input__inner {
    color: #575757;
  }

  .search {
    margin: 15px 0;
  }

  .search .el-col {
    margin: 5px 0;
  }

  ::v-deep .el-upload-dragger {
    width: 200px;
    height: 200px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
  }

  .avatar {
    width: 200px;
    height: 200px;
    display: block;
  }
  ::v-deep .el-dialog{
    border-radius: 10px;
    min-width: 300px;
  }
  /* .DetList_item{
    border-bottom: 1px solid #a1a1a1;

  }
  .DetList_item:nth-child(odd){
     border-right: 1px solid #a1a1a1;
  } */
  .label,.context{
    margin: 10px 0;
    padding: 10px 0;
  }
</style>
